import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SectionContainer } from 'components/grid';
import { AccountFeature } from 'components/CreateAccount/AccountFeature';
import { Button } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import desktopTopConfetti from 'public/images-next/createAccount/topConfetti.svg';
import desktopBottomConfetti from 'public/images-next/createAccount/bottomConfetti.svg';
import mobileTopConfetti from 'public/images-next/createAccount/topConfettiMobile.svg';
import mobileBottomConfetti from 'public/images-next/createAccount/bottomConfettiMobile.svg';
import { componentViewed, createAccountEntryPointClick } from 'state/modules/analytics';

const StyledGridItem = styled(Grid)(() => ({
  '&.MuiGrid-item': {
    paddingLeft: '0',
  },
}));

const CreateAccount = () => {
  const dispatch = useDispatch();
  const dispatchClickEvent = (clickTarget) => {
    dispatch(createAccountEntryPointClick({ clickTarget }));
  };

  const onViewChange = (inView) => {
    if (inView) dispatch(componentViewed({ component: 'create account' }));
  };

  const { ref } = useInView({
    threshold: 0.5,
    onChange: onViewChange,
    triggerOnce: true,
    initialInView: true,
  });

  return (
    <SectionContainer backgroundColor={'transparent'}>
      <Grid container spacing={1} margin="56px 0 32px">
        <StyledGridItem item xs={12} lg={3}>
          <Box
            ref={ref}
            width={1}
            height={1}
            minHeight="80px"
            sx={(theme) => ({
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
              backgroundImage: `url(${mobileTopConfetti})`,
              [theme.breakpoints.up('lg')]: {
                backgroundImage: `url(${desktopTopConfetti})`,
              },
            })}
          />
        </StyledGridItem>
        <StyledGridItem item textAlign="center" xs={12} lg="6">
          <Typography variant="h2">{"It's time to start celebrating! 🎉"}</Typography>
          <Typography variant="body1">
            Find party inspiration, book local vendors, and plan your upcoming event—all in one
            place.
          </Typography>
        </StyledGridItem>
        <StyledGridItem item xs={12} lg={3} />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={4}>
          <AccountFeature
            header="Get Inspired"
            title="Party Themes & Ideas"
            description="Check out hundreds of articles and curated tips to help build your event vision."
            imgUrl="https://media-api.xogrp.com/images/48698120-7c6f-4cd4-b495-b2fad6ff4171"
            linkText="Explore Ideas"
            linkPath="/articles"
            onClick={(clickTarget) => dispatchClickEvent(clickTarget)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <AccountFeature
            header="Wow Your Guests"
            title="Local Vendors & Entertainers"
            description="Let us find the perfect vendors in your area and quickly get price quotes for your event."
            imgUrl="https://media-api.xogrp.com/images/059d3103-acbc-4860-8b78-29ed31d11354"
            linkText="Find Vendors"
            linkPath="/event-expert"
            onClick={(clickTarget) => dispatchClickEvent(clickTarget)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <AccountFeature
            header="Plan with Confidence"
            title="Free Planning Tools"
            description="Use our event budgeter and checklist to guide you while planning—you’ll be a pro in no time."
            imgUrl="https://media-api.xogrp.com/images/1a1db1e4-af5b-4ea2-902c-a16f8a727fb6"
            linkText="Get Planning Tools"
            linkPath="https://info.thebash.com/budget-checklist"
            onClick={(clickTarget) => dispatchClickEvent(clickTarget)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} margin="32px 0 56px">
        <StyledGridItem item xs={3} />
        <StyledGridItem item xs={6} textAlign="center">
          <Typography variant="h2">Ready to start celebrating?</Typography>
          <Button
            type="primary"
            href="https://info.thebash.com/budget-checklist"
            sx={{ TextTransform: 'uppercase' }}
            onClick={() => dispatchClickEvent('Create Account')}
          >
            Get Started
          </Button>
        </StyledGridItem>
        <StyledGridItem item xs={12} lg={3}>
          <Box
            height={1}
            minHeight="80px"
            sx={(theme) => ({
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundImage: `url(${mobileBottomConfetti})`,
              marginTop: '28px',
              marginRight: '40px',
              [theme.breakpoints.up('lg')]: {
                backgroundImage: `url(${desktopBottomConfetti})`,
                backgroundPosition: 'right',
              },
            })}
          />
        </StyledGridItem>
      </Grid>
    </SectionContainer>
  );
};

export default CreateAccount;
